var fw_ADMIN = function(){
	if(typeof(fw_ADMIN) !== "undefined") return fw_ADMIN;
	var $admin = {};
	$admin = {
		addToMessagesPanel:function(message_info){
			var global_msg_block = $("#global_majax_messages_button");
			var message = $($admin.getMessageContent(message_info));
			message.mousemove(function(){
				$(this).unbind("mousemove");
				$(this).stop().prependTo("#global_majax_messages_block .majax_messajes_history_block .history_content");
			}).delay(3000).fadeOut(1000,function() {
				$(this).prependTo("#global_majax_messages_block .majax_messajes_history_block .history_content");
				$(this).unbind("mousemove");
				$(this).show();
			});
			global_msg_block.find(".admin_visible_block .visible_content").append(message);
		},
		getMessageContent:function(mess){
			return MESSAGES.get.messageHTMLInDropDownList(mess);
		},
		addToDebugPanel:function(debug){
			$("#system_debug_panel").stop().animate({ height:447 },400);
			$("#system_debug_panel_show_but").addClass('active');
			$("#system_debug_panel_show_but").addClass('toggled');
			$("#system_debug_bar").prepend($admin.getDebugContent(debug));
		},
		getDebugContent:function(debug){
			return $(
				'<div class="system_debug_message this_message_from_ajax_debug message_type_'+debug.type+'">'+
					'<p class="debug_source">ajax</p>'+
					'<p class="debug_url">'+debug.from+'</p>'+
					'<p class="debug_value">'+debug.message+'1</p>'+
					'<p class="debug_value"><span class="primitive_value value_is_string">'+(debug.already_formatted ? debug.value : fw_MAPLATER.print_r(debug.value))+'</span></p>'+
					'</div>'
			);
		}
	};
	return $admin;
}();