var _SLIDERS = function () {
	"use strict";
	var debug = false;
	var dd = function (message) {
		if (debug) console.log(message);
	};
	var $private = {
		sliderParams: {},
		selectors: {
			root_banner_id: '#main-banner',
			landing_banner_id: '#landing-banner',
			review_slider_id: '#review-slider',
			faq_slider_id: "#faq-slider",
			center_mode: ".center-slider .elements",
			dots_place: '.dots',
			arrows_place: '.arrows'
		},
		functions: {
			buildSimpleSlickSlider: function (selector) {
				if ($(selector).length === 0) {
					dd('buildSimpleSlickSlider | Не найден элемент с селектором ' + selector);
					return false;
				}
				$(selector).slick($private.sliderParams);
				$private.sliderParams = {};
			}
		},
		helpers: {},
		events: {},
		init: {
			rootBanner: function () {
				$private.sliderParams = {
					arrows: true,
					dots: false,
					autoplay: true,
					autoplaySpeed: 10000
				};
				$($private.functions.buildSimpleSlickSlider($private.selectors.root_banner_id));
			},
			landingBanner: function () {
				$private.sliderParams = {
					arrows: false,
					dots: true,
					autoplay: true,
					autoplaySpeed: 10000
				};
				$($private.functions.buildSimpleSlickSlider($private.selectors.landing_banner_id));
			},
			reviewSlider: function () {
				$private.sliderParams = {
					dots: true,
					appendDots: $private.selectors.dots_place,
					appendArrows: $private.selectors.arrows_place
				};
				$($private.functions.buildSimpleSlickSlider($private.selectors.review_slider_id));
			},
			faqSlider: function () {
				$private.sliderParams = {
					dots: true,
					appendDots: $private.selectors.dots_place,
					appendArrows: $private.selectors.arrows_place,
					adaptiveHeight: true
				};
				$($private.functions.buildSimpleSlickSlider($private.selectors.faq_slider_id));
			},
			centerMode: function () {
				$private.sliderParams = {
					centerMode: true,
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: true,
					dots: true,
					appendDots: $private.selectors.dots_place,
					appendArrows: $private.selectors.arrows_place
				};
				$($private.functions.buildSimpleSlickSlider($private.selectors.center_mode));
			}
		}
	};
	return {
		initRootBanner: function () {
			$private.init.rootBanner();
			$private.init.landingBanner();
			$private.init.reviewSlider();
			$private.init.faqSlider();
			$private.init.centerMode();
		}()
	};
}();